import React, {useEffect} from "react"
// import "../../components/DashboardComponents/dashboard.css"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import LayoutProvider from "../../../../components/Layout"
import AntecedentesComponent from "../../../../components/ReclamosComponents/Antecedentes/Antecedentes"
import { navigate } from 'gatsby';

const AntecedentesPage = ({ location }) => {
  
  useEffect(() => {
    const logueado = localStorage.getItem("logueado")
    const requiere_actualizar = localStorage.getItem("requiere_actualizar")

    if (logueado === false || logueado === null || logueado === undefined) {
        navigate("/")
    } else if (requiere_actualizar === true || requiere_actualizar === "true") {
        navigate("/inicio/editar-perfil/")
    }
  }, [])

  return (
    <LayoutProvider itle="Agregar Antecedentes" location={location}>
      {/* <p className="title-dashboard-ss">
        Agregar Antecedentes Contra Isapre o Fonasa
      </p> */}

      <Container fluid className="container-dashboard-ss">
        <Row>
          <AntecedentesComponent location={location} from={'detalle'}/>
        </Row>
      </Container>
    </LayoutProvider>
  )
}

export default AntecedentesPage
